.gradient1 {
  /* background: linear-gradient(75deg, #238b21, #337c56); */
  background-color: #003CBE;
  background-image: radial-gradient(
      at 92% 83%,
      #003CBE 0px,
      transparent 50%
    ),
    radial-gradient(at 4% 6%, rgb(31, 20, 112) 0px, transparent 50%),
    radial-gradient(at 92% 12%, rgb(10, 16, 77) 0px, transparent 50%),
    radial-gradient(at 26% 60%, rgb(33, 11, 86) 0px, transparent 50%);
}
