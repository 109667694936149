.circles {
  animation: animate 25s linear infinite;
}

.circles-reverse {
  animation: animate2 60s linear infinite;
}

@media only screen and (max-width: 859px) {
  .circles {
    animation: fade-in 10s ease-in-out;
  }
  .circles-reverse {
    animation: fade-in-up 10s ease-in-out;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }

  30% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }

  30% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: translateY(400px);
  }

  3% {
    opacity: 1;
  }
  97% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(-400px);
  }
}

@keyframes animate2 {
  0% {
    opacity: 0;
    transform: translateY(-30%);
  }

  2% {
    opacity: 1;
  }
  98% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}

@keyframes scroll {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}

.animate-scroll {
  animation: scroll 15s linear infinite;
}
